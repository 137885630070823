import { clsx, type ClassValue } from "clsx";
import { differenceInMonths, formatDuration } from "date-fns";
import { format } from "date-fns/format";
import { ptBR } from "date-fns/locale/pt-BR";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const compact = <T extends object>(obj: T) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v !== "")
  );
};

export function debounce(this: any, func: (_: string) => void, wait: number) {
  let timer: NodeJS.Timeout;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export const formatDate = (date: string) => {
  return format(new Date(date), "dd MMM. 'de' yyyy", {
    locale: ptBR,
  }).toLowerCase();
};

export const formatCurrency = (cents: number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(cents / 100);
};

export function calculateAgeInMonths(birthdate: Date): number {
  const today = new Date();
  return differenceInMonths(today, birthdate);
}

export const parseSessionDate = (dateInMonths: number) => {
  if (dateInMonths === 0) {
    return "Nascimento";
  }

  if (dateInMonths % 12 === 0) {
    const years = dateInMonths / 12;
    return formatDuration(
      { years },
      {
        locale: ptBR,
        format: ["years"],
        zero: false,
      }
    ).toLowerCase();
  }

  return formatDuration(
    { months: dateInMonths },
    {
      locale: ptBR,
      format: ["months"],
      zero: false,
    }
  ).toLowerCase();
};
