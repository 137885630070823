"use client";

import { AlertCircle, RefreshCcw } from "lucide-react";
import { useEffect } from "react";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <>
      <div className="flex items-center justify-center min-h-screen p-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="flex items-center gap-2 text-rose-600">
              <AlertCircle className="w-6 h-6" />
              Ops! Algo deu errado
            </CardTitle>
          </CardHeader>
          <CardContent>
            Desculpe, ocorreu um <strong>erro inesperado</strong>.
            <div className="px-4 py-3 mt-2 mb-4 text-sm bg-gray-100 rounded-md">
              <span className="font-bold">Error message:</span> {error.message}
            </div>
            Nossa equipe foi notificada e está trabalhando para resolver o
            problema.
            <Button
              onClick={() => reset()}
              className="w-full bg-rose-600 hover:bg-rose-700 mt-4"
            >
              <RefreshCcw className="w-4 h-4 mr-2" />
              Tentar novamente
            </Button>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
